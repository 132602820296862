<template>
    <div>
        <el-table
        v-if='tableData.length != 0'
        size="small"
        :data="tableData"
        height="calc(100vh - 265px)"
        style="width: 100%">
            <el-table-column
                type="index"
                label="序号"
                align="center"
                width="100">
            </el-table-column>
            <el-table-column
                prop="orginName"
                label="邀请成员"
                align="center">
            </el-table-column>
            <el-table-column
                prop="createUserUserName"
                label="邀请人"
                align="center">
            </el-table-column>
            <el-table-column
                label="状态"
                align="center">
                <template slot-scope="scope">
                    <el-tag type="info" v-if="scope.row.status == 0">待处理</el-tag>
                    <el-tag type="success" v-if="scope.row.status == 1">通过</el-tag>
                    <el-tag type="danger" v-if="scope.row.status == 2">拒绝</el-tag>
                </template>
            </el-table-column>
            <el-table-column
                label="邀请日期"
                align="center">
                <template slot-scope="scope">
                    {{scope.row.createDate | date}}
                </template>
            </el-table-column>
            <el-table-column
            label="操作"
            align="center">
                <template slot-scope="scope">
                    <el-button type="text" size="small" style="color: #67C23A;" v-if="scope.row.status == 0" @click="agreeBtn(scope.row, 1)">通过</el-button>
                    <el-button type="text" size="small" style="color: #F56C6C;" v-if="scope.row.status == 0" @click="agreeBtn(scope.row, 2)">拒绝</el-button>
                </template>
            </el-table-column>
        </el-table>
        <div class="no-data-box" v-if='tableData.length == 0'>
            <img src="@/assets/no-file.svg" alt="">
            <span>暂无数据</span>
        </div>

        <el-pagination v-if='tableData.length != 0'
        class="pagebox"
        @current-change="handleCurrentChange"
        :current-page="forLine.pageNum"
        :page-size="forLine.pageSize"
        layout="total,  prev, pager, next, jumper"
        :total="total">
        </el-pagination>
    </div>
</template>

<script>
import { orgAPI } from 'liankong-ui-api' 
export default {
    data() {
        return {
            tableData: [],
            total: 0,
            forLine: {
                name: '',
                pageNum: 1,
	            pageSize: 10
            }
        }
    },
    mounted() {
        this.getIntivaData();

        this.$ebus.$on('handleAuditSearchBtn', val=>{
            this.forLine.name = val.names;
            this.getIntivaData()
        })
    },
    methods: {
        getIntivaData() {
            orgAPI.getUserInviteList(this.forLine).then(res=>{
                if(res.code == 0) {
                    this.tableData = res.data;
                    this.total = res.total;
                } else {
                    this.$message.error(res.message);
                }
            }).catch(error=>{
                this.$message.error(error.message);
            })
        },
        handleCurrentChange(num) {
            this.forLine.pageNum = num;
            this.getIntivaData();
        },
        // 审批
        agreeBtn(row, num) {
            orgAPI.userApproveInvite({
                id: row.id,
                state: num
            }).then(res=>{
                if(res.code == 0) {
                    this.$message.success(num == 1 ? '审批成功' : '拒绝成功');
                    this.getIntivaData();
                } else {
                    this.$message.error(res.message);
                }
            }).catch(error=>{
                this.$message.error(error.message);
            })
        }
    },
    beforeDestroy() {
        this.$ebus.$off('handleAuditSearchBtn');
    }
}
</script>
<style lang="less" scoped>
/deep/ .el-table {
    .el-table__header-wrapper {
        .has-gutter {
            tr th{
                border:none;
                background: #F2F2F2;
                color: #666;
            }
        }
    }
    .el-table__body-wrapper {
        tr td {
            border:none;
            padding: 12px 0;
        }
    }
    &::before {
        height: 0px;
    }
}
/deep/ .pagebox {
    margin-top: 20px;
    text-align: left;
}
</style>